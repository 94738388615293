import { create } from 'zustand'

export enum DialogType {
  createOrganization = 'createOrganization',
}

interface DialogStoreState {
  activeDialog: DialogType | null
  setActiveDialog: (activeDialog: DialogType | null) => void
}

export const useDialogStore = create<DialogStoreState>((set, get) => ({
  activeDialog: null,
  setActiveDialog: (activeDialog: DialogType | null) => set({ activeDialog }),
}))
