import { CustomAvatar } from '@/components/CustomAvatar';
import { HStack } from '@/components/HStack';
import { OrganizationContext } from '@/components/auth/OrganizationContext';
import { UserContext } from '@/components/auth/UserContext';
import { __unsafe_useAuthStore } from '@/components/store/AuthStore';
import { DialogType, useDialogStore } from '@/components/store/DialogStore';
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuPortal, DropdownMenuSeparator, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { useTheme } from 'next-themes';
import { useRouter } from 'next/router';
import { useContext } from 'react';
export const OrganizationDropdown = () => {
  const router = useRouter();
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const {
    theme,
    setTheme
  } = useTheme();
  const {
    logout
  } = __unsafe_useAuthStore();
  const setActiveDialog = useDialogStore(state => state.setActiveDialog);
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="OrganizationDropdown" data-sentry-source-file="OrganizationDropdown.tsx">
      <DropdownMenuTrigger className="focus:outline-none cursor-pointer" data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="OrganizationDropdown.tsx">
        <HStack className="space-x-[10px] px-[4px] py-[4px] opacity-90 hover:opacity-100 rounded-[6px] hover:bg-primary/5 w-fit cursor-pointer select-none" data-sentry-element="HStack" data-sentry-source-file="OrganizationDropdown.tsx">
          <CustomAvatar avatar={organization?.avatarId} display={organization?.name ?? ''} className="rounded-[8px] w-[32px] h-[32px] text-[14px] font-bold" data-sentry-element="CustomAvatar" data-sentry-source-file="OrganizationDropdown.tsx" />
          <HStack className="space-x-[4px]" data-sentry-element="HStack" data-sentry-source-file="OrganizationDropdown.tsx">
            <div className="font-semibold text-[15px]">{organization?.name}</div>
            <IconChevronDown size={14} data-sentry-element="IconChevronDown" data-sentry-source-file="OrganizationDropdown.tsx" />
          </HStack>
        </HStack>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="min-w-[256px]" data-sentry-element="DropdownMenuContent" data-sentry-source-file="OrganizationDropdown.tsx">
        <DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-source-file="OrganizationDropdown.tsx">
          <DropdownMenuItem onSelect={() => router.push(`/${organization.slug}/account`)} data-sentry-element="DropdownMenuItem" data-sentry-source-file="OrganizationDropdown.tsx">My account</DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="OrganizationDropdown.tsx" />
        <DropdownMenuGroup data-sentry-element="DropdownMenuGroup" data-sentry-source-file="OrganizationDropdown.tsx">
          <DropdownMenuItem onSelect={() => router.push(`/${organization.slug}/members`)} data-sentry-element="DropdownMenuItem" data-sentry-source-file="OrganizationDropdown.tsx">
            Invite and manage members
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="OrganizationDropdown.tsx" />
        <DropdownMenuItem onSelect={() => setTheme(theme === 'dark' ? 'light' : 'dark')} data-sentry-element="DropdownMenuItem" data-sentry-source-file="OrganizationDropdown.tsx">
          Switch to {theme === 'dark' ? 'light' : 'dark'} mode
        </DropdownMenuItem>
        <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="OrganizationDropdown.tsx" />
        <DropdownMenuSub data-sentry-element="DropdownMenuSub" data-sentry-source-file="OrganizationDropdown.tsx">
          <DropdownMenuSubTrigger data-sentry-element="DropdownMenuSubTrigger" data-sentry-source-file="OrganizationDropdown.tsx">Switch organizations</DropdownMenuSubTrigger>
          <DropdownMenuPortal data-sentry-element="DropdownMenuPortal" data-sentry-source-file="OrganizationDropdown.tsx">
            <DropdownMenuSubContent data-sentry-element="DropdownMenuSubContent" data-sentry-source-file="OrganizationDropdown.tsx">
              {user.organizations.map(org => <DropdownMenuItem key={org.id} onSelect={() => router.push(`/${org.slug}`).catch(e => console.error(e))}>
                  <HStack className="w-full justify-between">
                    <div>{org.name}</div>
                    {org.slug === organization.slug && <IconCheck size={14} />}
                  </HStack>
                </DropdownMenuItem>)}
              <DropdownMenuSeparator data-sentry-element="DropdownMenuSeparator" data-sentry-source-file="OrganizationDropdown.tsx" />
              <DropdownMenuItem onSelect={() => setActiveDialog(DialogType.createOrganization)} data-sentry-element="DropdownMenuItem" data-sentry-source-file="OrganizationDropdown.tsx">
                Create a new organization...
              </DropdownMenuItem>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
        <DropdownMenuItem onSelect={() => logout()} data-sentry-element="DropdownMenuItem" data-sentry-source-file="OrganizationDropdown.tsx">Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>;
};